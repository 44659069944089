<template>
  <div class="thank-you">
    <section class="h-screen flex items-center text-center">
      <div class="flex flex-col items-center justify-between max-w-screen-lg px-4 mx-auto py-8">
        <h1 class="logo mb-8"><img src="images/logo.png"></h1>
        <h3 class="mb-4 text-2xl font-bold leading-none lg:text-4xl lg:mb-6">Message Sending Successful!</h3>
        <p class="leading-normal lg:text-lg mb-10">Thank you for your kind interest in Lee Dentalcare Pte Ltd. We will get back to you as soon as possible.</p>
        <a href="/" class="inline-block px-6 py-4 text-lg text-white bg-siteBlue">Back to Homepage</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    const storedDataLayer = sessionStorage.getItem('formData');
    if (storedDataLayer){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(JSON.parse(storedDataLayer));

      // remove form data from sessionStorage
      sessionStorage.removeItem('formData');
    }
  }
}
</script>
